@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

#vc-header {
    background-color: $kat-squid-ink-700;
    color: white;
    font-family: $font-family-base;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
}

.vc-header {
    margin-bottom: 30px;
}
