@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

#vc-footer {
    width: 100%;
    align-items: center;
    background-color: #f4f4f4;
    font-family: $font-family-base;

    .terms-of-use {
        padding-right: 3px;
    }
}

.vc-footer-top {
    display: flex;
    font-size: 14px;
    justify-content: center;
    line-height: 20px;
    padding-bottom: 10px;

    a {
        font-size: 14px;
        line-height: 20px;
    }
}

.cookie-policy {
    display: flex;
    font-size: 12px;
    line-height: 16px;
    white-space: normal;
    margin-left: 110px;
    text-align: center;
}
