@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';
// Please scope any global styles to within a class. Otherwise, we will leak our styles onto the Mons page body and
// cause unintended consequences.
.vc-style-aui-anchors {
    a {
        color: #007185;
        cursor: pointer;
        font-family: Verdana, sans-serif;
        font-size: 12px;
        line-height: 16px;
        text-decoration: none;
    }

    a:visited {
        color: #007185;
    }

    a:hover {
        color: #c7511f;
        text-decoration: underline;
    }
}

// Horizontally mirrors an element for RTL locales
[dir='rtl'] #vc-header-container .mirror-rtl,
[dir='rtl'] #vc-footer-container .mirror-rtl {
    transform: scaleX(-1);
}
