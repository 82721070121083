@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

.kat-input-root {
    position: relative;
}

.kat-input-field {
    border-radius: 1px;
    border: 1px solid $casper;
    box-shadow: inset 0 1px 2px 0 $input-box-shadow-color;
    box-sizing: border-box;
    color: $nordic;
    font-size: 0.8125rem;
    font-weight: 400;
    outline: none;
    padding: 0.5rem;
    width: 100%;

    &:focus {
        border: 1px solid $mystic;
    }

    &::placeholder {
        color: $tarpon;
        font-style: italic;
    }

    &-has-icon {
        text-indent: 20px;
    }
}

.kat-input-icon {
    filter: invert(76%) sepia(14%) saturate(168%) hue-rotate(136deg) brightness(95%) contrast(84%); // Using filter to recolor the SVG to $casper, see https://stackoverflow.com/a/59411103
    left: 5px;
    position: absolute;
    top: 7px;
}
