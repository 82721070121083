@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

.toast-root {
    align-items: center;
    display: flex;
    background-color: rgb(0 0 0 / 65%);
    border-radius: 4px;
    color: $kat-white;
    cursor: pointer;
    position: fixed;
    padding: 4px 8px;
    left: 50%;
    top: 56px;
    font-size: 13px;
    line-height: 18px;
    transform: translateX(-50%);
    z-index: $nav-toast-index;
}

.toast-dismiss {
    filter: invert(1);
    margin-left: 8px;
}
