@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

.flyout-menu-item-container {
    color: $kat-squid-ink-700;
    cursor: pointer; // Override conflicting CSS style
    display: flex;
    flex-direction: row;
    padding: $settings-list-vert-padding $settings-list-horiz-padding;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
        background-color: $kat-squid-ink-50;
        outline: none;
    }

    &:active,
    &:focus {
        outline: $default-outline $kat-aqua-400;
    }
}
