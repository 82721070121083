@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

.favorite-pages-overflow {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 2px); // Fit within viewport
    overflow-y: auto; // Add scrollbar if contents are larger than viewport

    a {
        color: $kat-squid-ink-700;
        font-size: 13px;
        line-height: 18px;
        padding: 7px 14px;
        text-decoration: none;

        &:active,
        &:focus,
        &:hover {
            background-color: $azure;
        }
    }
}

#favorite-pages-links-list.favorite-pages-links-list {
    // Override global style set on <a>
    box-sizing: content-box;
    display: flex;
    flex-wrap: wrap;
    height: 34px; // Must match the anchor's outer height to cause wrapping
    overflow-y: hidden;
    margin: 0 16px;
    padding: 2px; // Prevent overflow-y from hiding the outline applied when the <a> are keyboard focused

    a {
        padding: 8px 12px;
        color: white;
        cursor: pointer;
        font-size: 13px;
        line-height: 18px;
        margin-right: 18px;
        text-decoration: none;

        &:hover {
            outline: $default-outline $kat-white;
            border-radius: 1px;
        }

        &:focus {
            outline: $default-outline $kat-aqua-300;
            border-radius: 1px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.favorite-pages-links-root {
    align-items: center;
    display: flex;
}

.favorite-pages-overflow-icon {
    filter: invert(1);
    flex: 0 0 auto;
}
