@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

.header-logo {
    &--advantage {
        background-image: url('../../../images/advantage.svg');
        height: 34px;
        width: 146px;
    }

    &--advantage-ca {
        background-image: url('../../../images/advantage-ca.svg');
        height: 34px;
        width: 146px;
    }

    &--advantage-eu {
        background-image: url('../../../images/advantage-eu.svg');
        height: 34px;
        width: 146px;
    }

    &--advantage-jp {
        background-image: url('../../../images/advantage-jp.svg');
        height: 34px;
        width: 146px;
    }

    &--amazon {
        background-image: url('../../../images/amazon.svg');
        height: 30px;
        width: 63px;
    }

    &--vendorCentral {
        background-image: url('../../../images/vendorCentral.svg');
        height: 27px;
        width: 174px;
    }

    &--vendorCentral-eu {
        background-image: url('../../../images/vendorCentral-eu.svg');
        height: 30px;
        width: 174px;
    }
}
