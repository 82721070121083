@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

$nav-body-width: calc(min(242px, 50vw)); // On mobile, it should take up only half of the screen
$negative-nav-body-width: calc(max(-242px, -50vw));

.side-nav {
    background-color: white;
    border: none; // Override conflicting CSS style
    color: $kat-squid-ink-700;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    height: 100%;
    left: 0;
    line-height: 20px;
    position: fixed;
    top: 0;
    width: $nav-body-width;
    z-index: $nav-body-index;

    :deep(.loading-wrapper-error) {
        padding: 14px 20px;
    }

    &-slide {
        &-enter-active,
        &-leave-active {
            transition: left 450ms;
        }

        &-enter,
        &-leave-to {
            left: $negative-nav-body-width;
        }
    }

    &-body {
        box-sizing: border-box;
        flex: 1;
        height: calc(100vh - #{$navbar-height});
        overflow-y: auto;
        padding: 10px 0;
        width: $nav-body-width;
    }

    &-backdrop {
        background-color: rgba($night, 0.8);
        inset: 0;
        height: 100%;
        opacity: 0;
        transition-duration: 250ms;
        z-index: $nav-backdrop-index;

        &-visible {
            position: fixed;
            opacity: 1;
        }
    }

    &-header {
        align-items: center;
        border-bottom: 1px solid $athens;
        display: flex;
        height: $navbar-height;
        flex-shrink: 0;
        font-weight: $font-weight-bold;
        padding: 0 16px;
    }

    &-close-button {
        cursor: pointer;
        display: flex;
        margin-right: 8px;

        &-icon {
            filter: invert(13%) sepia(43%) saturate(556%) hue-rotate(172deg) brightness(92%) contrast(86%); // Using filter to recolor the SVG to $kat-squid-ink-700, see https://stackoverflow.com/a/59411103
        }
    }

    &-tab {
        align-items: center;
        background-color: white;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        position: relative;
        padding: 10px 16px;

        &:hover {
            background-color: $kat-squid-ink-50;
        }

        &:focus,
        &:active:not(:focus-within) {
            box-shadow: inset 0 0 0 1px $kat-aqua-400;
            outline: none;
        }

        &-chevron {
            flex: 0 0 auto;
            height: 20px;
            width: 20px;

            &-icon {
                filter: invert(43%) sepia(8%) saturate(858%) hue-rotate(172deg) brightness(89%) contrast(87%); // Using filter to recolor the SVG to $kat-squid-ink-500, see https://stackoverflow.com/a/59411103
            }
        }

        &-label {
            display: flex;
            flex: 1 1 auto;
            margin-left: 4px; // Line up the label with the close icon in the header
            padding-right: 8px;
        }

        &-badge {
            flex: 0 0 auto;
            margin-right: 4px;
        }
    }
}
