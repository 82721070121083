@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

.favorite-pages-bar {
    align-items: center;
    background-color: $kat-squid-ink-700;
    border-top: 1px solid $kat-squid-ink-500;
    box-sizing: content-box; // Override conflicting CSS style
    display: flex;
    font-size: 13px;
    height: 48px;
    justify-content: space-between;
    line-height: 18px;
    padding: 0 14px;

    &--editing {
        min-height: 48px;
        height: unset;
    }
}

.favorite-pages-full {
    align-items: center;
    display: flex;
    margin-right: 18px;
}

.favorite-pages-full-icon {
    filter: invert(1);
    flex: 0 0 auto;
    margin: 0 17px 0 3px; // Icon appears horizontally centered under the 48px nav button above it
}

.favorite-pages-empty {
    display: flex;
    align-items: center;
    color: $casper;
    height: 100%;
}

.favorite-pages-empty-icon {
    filter: invert(74%) sepia(14%) saturate(155%) hue-rotate(136deg) brightness(97%) contrast(88%); // $casper
    flex-shrink: 0;
    margin: 0 2px;
}

.favorite-pages-error-icon {
    filter: invert(1);
    flex-shrink: 0;
    margin-right: 10px;
}

.favorite-pages-hide {
    color: white;
    cursor: pointer;
    margin-left: 18px;

    &:hover {
        text-decoration: underline;
    }
}

.favorite-pages-dismiss {
    cursor: pointer;

    &-icon {
        filter: invert(1);
    }
}
