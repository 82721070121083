@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

$column-width: 174px;
$gap-size: 8px; // Keep in sync with GAP_SIZE
$row-height: 58px; // Keep in sync with ROW_HEIGHT

.locale-list {
    &-root {
        padding: $gap-size;

        :deep(.loading-wrapper-error) {
            width: $flyout-default-width;
        }
    }

    &-header {
        border-bottom: 1px solid $athens;
        display: flex;
        flex-direction: column;
        margin: (-$gap-size) (-$gap_size) $gap-size (-$gap-size);
        padding: $gap-size;
    }

    &-title {
        color: $nordic;
        flex: 1 1 auto;
        font-size: 14px;
        font-weight: $font-weight-bold;
        line-height: 20px;
    }

    &-search {
        margin-top: 4px;
    }

    &-body {
        display: grid;
        gap: $gap-size;
        grid-auto-flow: column; // Add additional columns as necessary
        grid-template-rows: repeat(auto-fit, minmax(0, $row-height)); // Empty rows are collapsed
        min-width: $column-width * 2 + $gap-size; // Minimum two columns
    }

    &-item {
        border: 1px solid $mystic;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        height: $row-height;
        justify-content: space-between;
        line-height: 20px;
        padding: $gap-size;
        position: relative;
        text-decoration: none;
        width: $column-width;

        &:hover {
            background-color: $azure;
        }

        &:active,
        &:focus {
            box-shadow: inset 0 0 0 2px $eastern;
            outline: none;
        }

        &-checkmark {
            filter: invert(1); // Using filter to recolor the SVG to white, see https://stackoverflow.com/a/59411103
            position: absolute;
            right: 5px;
            top: 5px;
        }

        &-country {
            color: $tarpon;
        }

        &-language {
            color: $nordic;
            font-weight: $font-weight-bold;
        }

        &-selected {
            background-color: $eastern;
            border-color: $eastern;
            pointer-events: none;

            div {
                color: white;
            }
        }
    }

    &-no-results {
        align-items: center;
        color: $tarpon;
        display: flex;
        font-size: 14px;
        font-style: italic;
        height: $row-height;
        justify-content: center;
        line-height: 20px;
    }
}
