@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

.nav-dot {
    &-root {
        border-radius: 50%;
        height: 8px;
        width: 8px;
        color: white;
    }

    &-default {
        background-color: $nordic;
    }

    &-info {
        background-color: $eastern;
    }

    &-success {
        background-color: $fiji;
    }

    &-warning {
        background-color: $bloodorange;
    }
}
