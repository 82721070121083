@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

.favorite-pages-editor-container {
    width: 100%;
}

.favorite-pages-editor {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.favorite-pages-editor-info {
    color: white;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
}

.favorite-pages-editor-pages {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    .favorite-page-item {
        padding: 0 4px;
        background-color: $kat-squid-ink-700;
        box-shadow: none;
        color: white;

        .draggable-item {
            border: 1px solid $kat-squid-ink-500;
            height: 24px;
            display: flex;
            align-items: center;
            cursor: grab;
        }

        .draggable-icon {
            margin: 0 0 0 1px;
            width: 10px;
            height: 18px;
        }

        .label {
            padding: 0 5px;
        }

        .remove-button {
            padding: 0;
            border: none;
            outline: none;
            background-color: transparent;

            :deep(.kat-button-icon) {
                filter: invert(42%) sepia(12%) saturate(589%) hue-rotate(172deg) brightness(92%) contrast(87%); // $kat-squid-ink-500
            }

            &:hover {
                background-color: transparent;
            }
        }
    }

    .dragging-opacity {
        opacity: 0.4;
    }

    .inaccessible-favorite {
        color: $submarine;
    }
}

.favorite-pages-editor-buttons {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    flex: 0 0 auto;
    margin-left: 18px;
}

#favorite-pages-save-button {
    margin-left: 10px;
}
