@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

.flyout-menu-item {
    &-container {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        padding: 8px 14px;
        text-decoration: none;

        &:hover {
            background-color: $kat-squid-ink-50;
        }

        &:focus,
        &:active:not(:focus-within) {
            background-color: transparent;
            box-shadow: inset 0 0 0 1px $kat-aqua-400;
            outline: none;
        }
    }

    &-nav-link {
        align-content: center;
        color: $kat-squid-ink-700;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
        line-height: 24px;
    }

    &-label {
        margin-left: 14px;
    }

    &-external-link {
        align-self: center;
        display: flex;
        margin-left: 4px;

        &-icon {
            filter: invert(13%) sepia(43%) saturate(556%) hue-rotate(172deg) brightness(92%) contrast(86%); // Using filter to recolor the SVG to $kat-squid-ink-700, see https://stackoverflow.com/a/59411103
        }
    }

    &-badge {
        flex: 0 0 auto;
        margin-left: 4px;
    }

    &-favorite-page-button {
        box-sizing: content-box;
        flex: 0 0 auto;
        height: 14px;
        padding: 5px;
        margin-left: 4px;
        width: 14px;

        :deep(img) {
            filter: invert(13%) sepia(43%) saturate(556%) hue-rotate(172deg) brightness(92%) contrast(86%); // Using filter to recolor the SVG to $kat-squid-ink-700, see https://stackoverflow.com/a/59411103
        }
    }
}
