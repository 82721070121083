@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

#dropdown-account-switcher-container {
    align-items: center;
    display: flex;
    height: 48px;
}

.account-switcher-fallback {
    font-size: 12px;
    line-height: 16px;
}
