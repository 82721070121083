@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

.favorite-page-button {
    &-hidden {
        opacity: 0; // Hides the element but still allows it to be focusable by the keyboard
    }

    &:hover {
        background-color: $zircon;
    }

    &:active,
    &:focus,
    &:focus-within {
        box-shadow: inset 0 0 0 2px $mystic;
        outline: none;
    }
}
