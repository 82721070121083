@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

$divider-right: 1px $kat-squid-ink-500 solid;

#navbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    :deep(.account-switcher-fallback) {
        color: white;
        text-decoration: none;
    }
}

.nav-left-section {
    display: flex;
    flex-direction: row;
    height: $navbar-height;
    margin-right: 9px;
}

.market-switcher-wrapper {
    display: flex;
    justify-content: center;
}

.nav-button-container {
    border-right: $divider-right;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
}

// Total size should equal 36 x 36
.nav-button {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 1px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 7px;
    width: 100%;

    &:hover {
        border-color: white;
        outline: none;
    }

    &:active,
    &:focus {
        border-color: $kat-aqua-300;
        outline: none;
    }

    &-icon {
        filter: invert(1); // Makes the icon white
    }
}

.nav-right-section {
    align-items: center;
    display: flex;
    height: $navbar-height;
    margin-left: 9px;
    margin-right: 18px;
}

.logo-container {
    align-items: center;
    color: white;
    display: flex;
    padding-left: 16px;
    margin-right: 16px;
}

.logo-container-divider-right {
    height: 20px;
    border-right: $divider-right;
    padding-right: 16px;
}

.navbar-account-switcher {
    color: white;
    text-decoration: none;
}
