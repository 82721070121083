@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

.tiny {
    box-sizing: content-box;
    height: 14px;
    vertical-align: unset;
    width: 14px;
}

.small {
    box-sizing: content-box;
    height: 20px;
    vertical-align: unset;
    width: 20px;
}

.large {
    box-sizing: content-box;
    height: 40px;
    vertical-align: unset;
    width: 40px;
}
