@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

$settings-list-min-width: 192px;

.settings-list {
    color: $kat-squid-ink-700;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    min-width: $settings-list-min-width;

    :deep(.loading-wrapper-error),
    :deep(.loading-wrapper-loading) {
        padding: $settings-list-vert-padding $settings-list-horiz-padding;
        width: $settings-list-min-width;
    }

    &-divider {
        border-top: 1px solid $kat-squid-ink-100;
    }
}
