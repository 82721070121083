@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

$icon-margin-sides: 9px;

.utility-bar {
    align-items: center;
    display: flex;
    flex-direction: row;

    > div {
        display: flex;
        margin: 0 $icon-margin-sides;

        &:nth-of-type(1) {
            margin-left: 0;
        }

        &:nth-last-of-type(1) {
            margin-right: 0;
        }
    }

    &-button-link {
        color: white;
        display: flex;
        position: relative;
        text-decoration: none;
    }

    &-help {
        cursor: pointer;
    }

    &-icon {
        filter: invert(1); // Makes the icon white
    }

    &-locale {
        align-items: center;
        color: white;
        cursor: default;
        display: flex;

        &-icon-wrapper {
            align-items: center;
            display: flex;
            margin-right: -5px;
        }
    }

    &-settings {
        height: 20px;
        width: 20px;
    }

    &-favorite-pages-flyout {
        padding: 10px;
        width: 160px;
        color: $kat-squid-ink-700;
    }
}
