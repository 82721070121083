@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

.loading-wrapper-error {
    box-sizing: border-box;
    color: $kat-squid-ink-700;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: 400;
    height: 100%;
    line-height: 18px;
    width: 100%;

    &-header {
        font-size: 14px;
        font-weight: $font-weight-bold;
        line-height: 20px;
        margin-bottom: 8px;
    }
}

.loading-wrapper-loading {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}
