@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

.kat-badge {
    &-root {
        border-radius: 32px;
        box-sizing: border-box;
        color: white;
        display: inline-flex;
        flex: 1 1 auto;
        font-family: $font-family-base;
        font-size: 12px;
        font-weight: 400;
        height: 20px;
        letter-spacing: 0.06px;
        line-height: 12px;
        overflow: hidden;
        padding: 4px 8px;
        text-align: center;
        white-space: nowrap;
    }

    &-default {
        background-color: $nordic;
    }

    &-info {
        background-color: $eastern;
    }

    &-success {
        background-color: $fiji;
    }

    &-warning {
        background-color: $bloodorange;
    }
}
