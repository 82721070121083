// Add any variables and mixins here that you want accessible from any Vue component. Do not add styles or they will be
// duplicated for every Vue component!

// Z-indexes
$nav-backdrop-index: 2002;
$nav-body-index: 2003;
$nav-toast-index: 2004;

// Other trim-specific styles
$flyout-default-width: 192px;
$navbar-height: 48px;
$settings-list-horiz-padding: 16px;
$settings-list-vert-padding: 8px;
$default-outline: 1px solid;

// KDS 5 styles for components not yet using KDS 6
$athens: #d5dbdb;
$azure: #f5f9fa;
$bloodorange: #e02d00;
$casper: #aab7b8;
$eastern: #008296;
$fiji: #538000;
$font-family-base: 'Amazon Ember', Arial, sans-serif;
$font-weight-bold: bold;
$input-box-shadow-color: #e6e6e6;
$mystic: #7fc2bb;
$night: #333;
$nordic: #002f36;
$submarine: #879596;
$tarpon: #667575;
$lagoon: #006878;
$zircon: #e3eced;
$seattle: #ebeeef;
$shadow-gray: #b5b5b5;

// KDS 6 shadow
$shadow-gray: #b5b5b5;
$white: #fff;
$lagoon: #006878;
$zircon: #e3eced;
$seattle: #ebeeef;

// The Katal Visual Refresh (KDS 6) is still in active development. This was copied from https://code.amazon.com/packages/KatalComponents/blobs/mainline/--/src/styles/katal-theme/_variables.scss
$kat-aqua-50: #e2f4f7;
$kat-aqua-100: #aadfe7;
$kat-aqua-200: #74c6d2;
$kat-aqua-300: #28a5b8;
$kat-aqua-400: #138296;
$kat-aqua-500: #007587;
$kat-aqua-600: #005f6e;
$kat-aqua-700: #004954;
$kat-aqua-800: #003740;
$kat-blue-50: #ebf7ff;
$kat-blue-100: #b9dffb;
$kat-blue-200: #59b6f8;
$kat-blue-300: #239ef6;
$kat-blue-400: #0a8ff5;
$kat-blue-500: #0a6fc2;
$kat-blue-600: #0859a0;
$kat-blue-700: #074588;
$kat-blue-800: #063b73;
$kat-green-50: #f2f6e1;
$kat-green-100: #cddeac;
$kat-green-200: #a6c47a;
$kat-green-300: #7ea949;
$kat-green-400: #49850f;
$kat-green-500: #387000;
$kat-green-600: #306100;
$kat-green-700: #295200;
$kat-green-800: #1f3d00;
$kat-indigo-50: #ecebfd;
$kat-indigo-100: #c7c5fb;
$kat-indigo-200: #a29ef9;
$kat-indigo-300: #7e77f7;
$kat-indigo-400: #5048ea;
$kat-indigo-500: #4037e1;
$kat-indigo-600: #362ed1;
$kat-indigo-700: #251ea4;
$kat-indigo-800: #181371;
$kat-lime-50: #f9fbe6;
$kat-lime-100: #eaf2b7;
$kat-lime-200: #dce988;
$kat-lime-300: #cee058;
$kat-lime-400: #bad411;
$kat-lime-500: #aec610;
$kat-lime-600: #a2b80f;
$kat-lime-700: #91a50d;
$kat-lime-800: #7c8e0b;
$kat-magenta-50: #faebf5;
$kat-magenta-100: #f2c4e3;
$kat-magenta-200: #ea9ed1;
$kat-magenta-300: #e277be;
$kat-magenta-400: #da50ac;
$kat-magenta-500: #d63da3;
$kat-magenta-600: #c62a92;
$kat-magenta-700: #b52685;
$kat-magenta-800: #a02276;
$kat-orange-50: #fff3e5;
$kat-orange-100: #ffddb2;
$kat-orange-200: #ffc77f;
$kat-orange-300: #ffb04c;
$kat-orange-400: #f90;
$kat-orange-500: #f08000;
$kat-orange-600: #e07400;
$kat-orange-700: #c60;
$kat-orange-800: #b35300;
$kat-purple-50: #f7e5f5;
$kat-purple-100: #e6b4df;
$kat-purple-200: #d287c7;
$kat-purple-300: #b654a7;
$kat-purple-400: #8c297d;
$kat-purple-500: #7a246d;
$kat-purple-600: #6b1f5f;
$kat-purple-700: #53184a;
$kat-purple-800: #3f1238;
$kat-red-50: #ffe5df;
$kat-red-100: #fdb39f;
$kat-red-200: #fd8163;
$kat-red-300: #ff6038;
$kat-red-400: #e32e00;
$kat-red-500: #cf2900;
$kat-red-600: #bd2600;
$kat-red-700: #9e2000;
$kat-red-800: #801a00;
$kat-shadow-20: #090a0d33;
$kat-shadow-60: #090a0d99;
$kat-shadow-80: #090a0dcc;
$kat-squid-ink-50: #f7f7f8;
$kat-squid-ink-100: #ebeced;
$kat-squid-ink-200: #d3d5d8;
$kat-squid-ink-300: #bdc0c5;
$kat-squid-ink-400: #868d95;
$kat-squid-ink-500: #5e6a78;
$kat-squid-ink-600: #3f4a59;
$kat-squid-ink-700: #232f3e;
$kat-squid-ink-800: #161e2d;
$kat-white: #fff;
$kat-white-transparent: #fff0;
$kat-yellow-50: #fdf4d8;
$kat-yellow-100: #ffebaa;
$kat-yellow-200: #fcde7c;
$kat-yellow-300: #fdd34a;
$kat-yellow-400: #ffc400;
$kat-yellow-500: #ebac00;
$kat-yellow-600: #d19200;
$kat-yellow-700: #bd8100;
$kat-yellow-800: #a36a00;
