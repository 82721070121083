@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

.flyout-trigger-wrap {
    display: flex;
}

.flyout-flyout {
    background-color: white;
    border: 1px solid $kat-squid-ink-200;
    border-radius: 1px;
    position: relative;
    z-index: 100;
}

.flyout-content-wrap {
    background-color: white;
    position: relative;
    z-index: 1;
}

.flyout-arrow,
.flyout-arrow::before {
    height: 8px;
    position: absolute;
    left: -1px;
    width: 8px;
}

.flyout-arrow {
    &-bottom {
        top: -5px;
    }

    &-right {
        left: -5px;
    }

    &-left {
        right: -5px;
    }

    &-top {
        bottom: -5px;
    }
}

.flyout-arrow::before {
    background: white;
    border: 1px solid $kat-squid-ink-200;
    border-radius: 1px;
    content: '';
    transform: rotate(45deg);
}

.flyout-hoverable-area {
    inset: -10px 0 100%;
    position: absolute;
}
