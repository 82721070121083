@import '/local/p4clients/pkgbuild-const/workspace/src/VendorCentralTrimWebsite/src/scss/_variables.scss';

.flyout-menu {
    border-left: 1px solid rgb(0 0 0 / 20%);
    background-color: white;
    cursor: default;
    position: fixed;
    max-height: 100vh;
    overflow-y: auto;
    transition: 4ms;
    width: calc(min(300px, 50vw)); // On mobile, it should take up only half of the screen

    &-triangle-with-shadow {
        height: 40px;
        position: fixed;
        overflow: hidden;
        width: 40px;

        &::after {
            box-shadow: -1px 5px 9px -5px rgb(0 0 0 / 50%);
            background: white;
            content: '';
            position: absolute;
            height: 15px;
            left: 35px;
            transform: rotate(45deg);
            top: 14px;
            width: 15px;
        }
    }

    &-group-label {
        border-bottom: 1px solid $athens;
        color: $kat-squid-ink-700;
        font-size: 12px;
        font-weight: $font-weight-bold;
        line-height: 15px;
        margin: 14px 22px 7px;
        padding-bottom: 4px;
    }
}
